
          @import "~@/assets/styles/variables.scss";
          






























































































































.login-enter-view {
  width: 100vw;
  height: 100vh;
  background: url("@/assets/images/login-bg.png") 0 0 no-repeat;
  background-size: 100% 100%;

  .section-center-view {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1054px;
    height: 530px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(75, 66, 66, 0.1);
    box-sizing: border-box;
    padding: 0 54px;

    .identifying {
      display: flex;
      align-items: center;
      margin: 50px 0;

      .logo {
        width: 50px;
      }

      .schoolName {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        color: var(--theme-weaken-color-2);
        margin-left: 20px;
      }
    }

    .content {
      display: flex;
      height: 270px;
      justify-content: space-between;

      .left-exhibition-img-warp {
        width: 600px;
        height: 270px;
        border-radius: 4px;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .right-login-verification {
        width: 300px;

        /deep/.el-form-item__label {
          padding: 0;
          color: #323233;
        }
      }
    }

    .footer-school-notice {
      width: 600px;
      margin-top: 45px;
      display: flex;

      .strip {
        position: relative;
        font-size: 14px;
        box-sizing: border-box;
        padding-left: 10px;
        margin-right: 50px;

        .text {
          cursor: pointer;
          color: $fontColor;
        }

        .text:hover {
          color: var(--theme-weaken-color-2);
          text-decoration: underline;
        }
      }

      .strip:before {
        content: "";
        width: 4px;
        height: 4px;
        background-color: var(--theme-weaken-color-2);
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
